import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader, Chip,
  Container,
  createTheme,
  Grid, Skeleton,
  Stack,
  ThemeProvider,
  Typography, useMediaQuery
} from "@mui/material";
import {useEffect, useState} from "react";
import {CurrencyInterface} from "../single-currency/types";
import axios from "axios";
import * as React from "react";
import Blinker from "../../components/Blinker";
import DollarPrice from "../../components/DollarPrice";

const EMPTY_CURRENCY: CurrencyInterface = {
  cmcId: '',
  cmcRank: '',
  slug: '',
  name: '',
  nameFa: '',
  symbol: '',
  price: '',
  price_toman: '',
  percentChange1h: '',
  percentChange24h: '',
  percentChange7d: '',
  percentChange30d: '',
  percentChange60d: '',
  percentChange90d: '',
  percentChange180d: '',
  maxSupply: '',
  circulatingSupply: '',
  ath: '',
  atl: '',
  high24h: '',
  low24h: '',
  volume24h: '',
  marketCap: '',
  dominance: '',
  raise: 0,
  buy_button_status: 0,
  buy_button_link: '',
};

const currencyConvertor = (data: any): CurrencyInterface => ({
  ...data
})
export default function BtcPrice(){
  const [nameFontSize, setNameFontSize] = useState(250);
  const [symbolFontSize, setSymbolFontSize] = useState(50);
  const [symbolHeight, setSymbolHeight] = useState(72);
  const [usdtFontSize, setUsdtFontSize] = useState(150);
  const [priceFontSize, setPriceFontSize] = useState(500);
  const [IconSize, setIconSize] = useState(260);
  const [currency, setCurrency] = useState<CurrencyInterface>(EMPTY_CURRENCY);
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);

  const min1900px = useMediaQuery('(min-width:1900px)');
  const min1700px = useMediaQuery('(min-width:1700px) and (max-width:1899px)');
  const min1500px = useMediaQuery('(min-width:1500px) and (max-width:1699px)');
  const min1300px = useMediaQuery('(min-width:1300px) and (max-width:1499px)');
  const min1100px = useMediaQuery('(min-width:1100px) and (max-width:1299px)');
  const min900px = useMediaQuery('(min-width:900px) and (max-width:1099px)');
  const min700px = useMediaQuery('(min-width:700px) and (max-width:899px)');
  const min500px = useMediaQuery('(min-width:500px) and (max-width:699px)');
  const min300px = useMediaQuery('(min-width:300px) and (max-width:499px)');

  useEffect(() => {
    setLoading(true);
    axios.get(`https://appapi.ramzarz.news/api/cryptocurrency/single/bitcoin`).then(response => response.data)
      .then((data) => {
        setCurrency(currencyConvertor(data))
        setLoaded(true);
      }).catch((err) => {

    }).finally(() => setLoading(false))
  }, []);

  useEffect(() => {

    if (loaded) {
      const ws = new WebSocket('wss://ws.ramzarz.news/prices?assets=bitcoin');
      ws.onmessage = (event) => {
        try {
          const updates = JSON.parse(event.data);

          let currentCurrency = Object.assign({}, currency);
          Object.keys(updates).forEach((currencyName) => {
            if (currentCurrency.name.toLowerCase() === currencyName.toLowerCase()) {
              currentCurrency.price = updates[currencyName];
            }
          })

          setCurrency(currentCurrency);

        } catch (err) {
          //console.log(err);
        }


      }
      return () => {
        ws.close();
      }
    }
  }, [loaded]);

  useEffect(() => {
    if(min1900px){
      setNameFontSize(250);
      setSymbolFontSize(50);
      setSymbolHeight(72);
      setUsdtFontSize(150);
      setPriceFontSize(500);
      setIconSize(260);
    }
  }, [min1900px]);

  useEffect(() => {
    if(min1700px){
      setNameFontSize(230);
      setSymbolFontSize(45);
      setSymbolHeight(67);
      setUsdtFontSize(130);
      setPriceFontSize(450);
      setIconSize(240);
    }
  }, [min1700px]);

  useEffect(() => {
    if(min1500px){
      setNameFontSize(200);
      setSymbolFontSize(40);
      setSymbolHeight(62);
      setUsdtFontSize(110);
      setPriceFontSize(400);
      setIconSize(220);
    }
  }, [min1500px]);

  useEffect(() => {
    if(min1300px){
      setNameFontSize(170);
      setSymbolFontSize(35);
      setSymbolHeight(58);
      setUsdtFontSize(90);
      setPriceFontSize(350);
      setIconSize(200);
    }
  }, [min1300px]);

  useEffect(() => {
    if(min1100px){
      setNameFontSize(140);
      setSymbolFontSize(30);
      setSymbolHeight(50);
      setUsdtFontSize(75);
      setPriceFontSize(300);
      setIconSize(170);
    }
  }, [min1100px]);

  useEffect(() => {
    if(min900px){
      setNameFontSize(110);
      setSymbolFontSize(25);
      setSymbolHeight(45);
      setUsdtFontSize(65);
      setPriceFontSize(230);
      setIconSize(150);
    }
  }, [min900px]);

  useEffect(() => {
    if(min700px){
      setNameFontSize(95);
      setSymbolFontSize(20);
      setSymbolHeight(40);
      setUsdtFontSize(55);
      setPriceFontSize(180);
      setIconSize(130);
    }
  }, [min700px]);

  useEffect(() => {
    if(min500px){
      setNameFontSize(70);
      setSymbolFontSize(15);
      setSymbolHeight(30);
      setUsdtFontSize(40);
      setPriceFontSize(120);
      setIconSize(100);
    }
  }, [min500px]);

  useEffect(() => {
    if(min300px){
      setNameFontSize(30);
      setSymbolFontSize(10);
      setSymbolHeight(20);
      setUsdtFontSize(20);
      setPriceFontSize(60);
      setIconSize(40);
    }
  }, [min300px]);

  return (
    <Container maxWidth={false} dir={'ltr'} sx={{
      height: '100vh!important',
      width: '100vw!important',
      overflowX: 'hidden!important',
      overflowY: 'hidden!important',
      padding: '0px!important'
    }}>
      <Grid container sx={{height: '100%', width: '100%'}}>
        <Grid item xs={12} sx={{height: '100%', width: '100%'}}>
          {loading ? (
            <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
          ) : (
            <Card sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
              <CardHeader
                sx={{width: '100%'}}
                title={(
                  <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <Avatar src={'https://s2.coinmarketcap.com/static/img/coins/128x128/1.png'} sx={{ width: `${IconSize}px!important`, height: `${IconSize}px!important`}} aria-label="bitcoin">
                    </Avatar>
                    <Typography variant="h2" sx={{ color: 'text.primary', fontSize: `${nameFontSize}px!important` }}>{ currency.name }</Typography>
                    <Chip label={currency.symbol.toUpperCase()} sx={{fontSize: `${symbolFontSize}px!important`, height: `${symbolHeight}px!important`}} color={'info'} />
                  </Stack>
                )}
              />
              <CardContent sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                <Typography variant="h1" sx={{ color: 'text.primary', fontSize: `${priceFontSize}px!important` }}>
                  <Blinker mode={currency.raise}>
                    <DollarPrice price={currency.price} decimals={0} withoutSign/>
                  </Blinker>
                </Typography>
                <Typography sx={{fontSize: `${usdtFontSize}px!important`}} variant={'h5'}>USDT</Typography>
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}