import {Box, useTheme} from "@mui/material";
import * as React from "react";
import {memo, useEffect, useMemo, useState} from "react";

function ColorfulNumber(props: { n: string, sign?: string }) {
  const {
    n,
    sign
  } = props;
  const theme = useTheme();

  const [value, setValue] = useState(n);
  const [color, setColor] = useState(() => {
    return theme.palette.text.primary;
  });


  useEffect(() => {
    if (parseFloat(n) > parseFloat(value)) {
      setColor('green');
    } else if (parseFloat(n) < parseFloat(value)) {
      setColor('red');
    } else {
      setColor(theme.palette.text.primary)
    }
    setValue(n)
  }, [n]);

  useEffect(() => {
    const t = setTimeout(() => {
      setColor(theme.palette.text.primary);
    }, 1000)
    return () => {
      clearTimeout(t);
    }
  }, [color]);

  return <Box
    component='span'
    color={color}
    sx={{display: 'inline-block', direction: 'ltr', textAlign: 'left'}}
  >{sign}<Formatter num={value}/>
  </Box>
}

const Formatter = memo(function Formatter(props: { num: string }) {
  const {
    num
  } = props;
  const [formattedNum, setFormattedNum] = useState(num);

  useEffect(() => {
    let f = parseFloat(num);
    let fn;

    if (f === 0 || f >= 1 || f <= -1){
      fn = Intl.NumberFormat('en-US').format(Number(num));
    }else{
      let p = num.split(".");

      let i = 0;
      for (; i < p[1].length; i++) {
        if (parseInt(p[1][i]) === 0) continue;
        break;
      }

      let detectedPrecision = Math.min(p[1].length - 1, i);

      let maxPrecision = Math.min(p[1].length - 1, i + 3);

      if (detectedPrecision <= 4){
        fn = p[0] + '.' + p[1].slice(0, maxPrecision);
      }else{
        fn = `${p[0]} .00<sub style={{fontWeight: '200'}}>${detectedPrecision - 4}</sub>00${p[1].slice(i, p[1].length)}`;
      }
    }

    setFormattedNum(fn);
  }, [num])
  return (<span dangerouslySetInnerHTML={{__html: formattedNum}} />);
})

function detectPrecision(n: string, decimals?: number): string {
  let f = parseFloat(n);
  let m = n;

  let d = 2;
  if(decimals !== undefined){
    d = decimals;
  }

  if (f === 0) return '0';

  if (f >= 1 || f <= -1) return f.toFixed(d);

  if (n[0] == '.') {
    m = '0' + n;
  }
  let p = m.split(".");

  let i = 0;
  for (; i < p[1].length; i++) {
    if (parseInt(p[1][i]) === 0) continue;
    break;
  }

  let detectedPrecision = Math.min(p[1].length - 1, i + 3);


  return p[0] + '.' + p[1].slice(0, detectedPrecision);


}

export default function DollarPrice(props: { price: string, decimals?: number, withoutSign?: boolean }) {
  const {
    price,
    decimals,
    withoutSign
  } = props;

  const priceFloated = useMemo(() => detectPrecision(price, decimals), [price]);

  return (
    <Box component='span' sx={{display: 'inline-block', direction: 'ltr', textAlign: 'left'}}>
      <ColorfulNumber n={priceFloated} sign={withoutSign ? '' : '$'} />
    </Box>
  )
}
