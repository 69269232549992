import * as React from "react";
import Posts from "../Posts";
import {Box, useTheme} from "@mui/material";
import BlogIcon from "../Icons/BlogIcon";

const Icon = () => {
  const theme = useTheme();
  return (
    <Box component={'span'} pl={0.5}>
      <BlogIcon fill={ theme.palette.mode === 'light' ? 'black' : 'white'} size={24} type={'outlined'} />
    </Box>
  );
}

export default function ArticlesList() {
  return (
    <Posts
      url="https://app.ramzarz.news/node/api/v1/articles?per_page=20"
      title="جدیدترین مقالات"
      singlePath="/articles/:id/:slug"
      icon={<Icon />}
    ></Posts>
  );
}
