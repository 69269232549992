import React from "react";
import {createBrowserRouter} from "react-router-dom";

import Home from "../pages/home"
import News from "../pages/news"
import Articles from "../pages/articles"
import App from "../App";
import NotFound from "../pages/errors/NotFound";
import SingleNews from "../pages/single-news";
import SingleArticle from "../pages/single-article";
import SingleCurrency from "../pages/single-currency";
import Profile from "../pages/profile";
import About from "../pages/about";
import Terms from "../pages/terms";
import Search from "../pages/search";
import Exchanges from "../pages/exchanges";
import Blog from "../pages/blog";
import Ideas from "../pages/ideas";
import ErrorPage from "../pages/ErrorPage";
import BtcPrice from "../pages/live/btc-price";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "/currencies",
        element: <Home/>,
      },
      {
        path: "/news",
        element: <News/>,
      },
      {
        path: "/news/:id/:slug",
        element: <SingleNews/>,
      },
      {
        path: "/articles",
        element: <Articles/>,
      },
      {
        path: "/articles/:id/:slug",
        element: <SingleArticle/>,
      },
      {
        path: "/blog",
        element: <Blog/>,
      },
      {
        path: "/ideas",
        element: <Ideas/>,
      },
      {
        path: "/calculator",
        element: <Home/>,
      },
      {
        path: "/exchanges/:currency",
        element: <Exchanges/>,
      },
    ],
  },
  {
    errorElement: <ErrorPage />,
    path: "/currencies/:slug",
    element: <SingleCurrency/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search",
    element: <Search/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search/ideas",
    element: <Search/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search/articles",
    element: <Search/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/search/blog",
    element: <Search/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/profile",
    element: <Profile/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/about",
    element: <About/>,
  },
  {
    errorElement: <ErrorPage />,
    path: "/terms",
    element: <Terms/>,
  },
  {
    path: "/live/btcprice",
    element: <BtcPrice/>,
  },
]);

export default router;
