import {initializeApp, } from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyCtIeOsohgE7FnLuxfEP5O8qJcwbhEGwkc",
  authDomain: "ramzarz-app-3dfa5.firebaseapp.com",
  projectId: "ramzarz-app-3dfa5",
  storageBucket: "ramzarz-app-3dfa5.appspot.com",
  messagingSenderId: "585077318598",
  appId: "1:585077318598:web:53bb964a5d0a8466ad08c7"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export async function requestPermission() {
  if (typeof Notification !== 'undefined') {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging);
      localStorage.setItem('firebase_token', token);
      if(!localStorage.getItem('firebase_token')){

      }
    }
  }
}